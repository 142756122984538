<template>
  <div
    class="flex justify-between max-h-full w-full lg:p-6 flex-col lg:flex-row space-x-4 lg:space-x-6 p-4 base-content"
  >
    <div class="lg:w-1/2">
    <label for="#" class="px-0.5 pb-1">Default Subject Email Sequence</label>
      <dropdown
        reposition
        searchable
        placeholder="Default Subject Email Sequence"
        :options="email_option.options"
        @input="(value) => setValue(value, 'candidate')"
        :value="selected_sequence.candidate"
        :limit="1"
        maxWidth="100%"
        minWidth="100%"
        :config="{ label: 'name', trackBy: 'id' }"
        :multiple="false"
        :disabled="email_option.isLoading"
        :loading="email_option.isLoading"
        :taggable="false"
      />
    </div>
    <div class="lg:w-1/2">
    <label for="#" class="px-0.5 pb-1">Default Referee Email Sequence</label>
      <dropdown
        reposition
        searchable
        placeholder="Default Referee Email Sequence"
        :options="email_option.options"
        @input="(value) => setValue(value, 'referee')"
        :value="selected_sequence.referee"
        :limit="1"
        :loading="email_option.isLoading"
        :disabled="email_option.isLoading"

        maxWidth="100%"
        minWidth="100%"
        :config="{ label: 'name', trackBy: 'id' }"
        :multiple="false"
        :taggable="false"
      />
    </div>
  </div>
</template>

<script>
import Dropdown from "@shared/components/dropdown-base";
import axios from "@/axios";
import { mapGetters } from "vuex";
export default {
  components: {
    Dropdown,
  },
  data() {
    return {
      package_id: "",
      email_option: {
        options: [],
        referee: [],
        isLoading: false,
      },
      selected_sequence: {
        candidate: null,
        referee: null,
      },
    };
  },
  computed: {
    ...mapGetters(["getTenantId"]),
  },
  async mounted() {
    // this.$store.dispatch("")
    if (!this.$store.getters.getTenantId) {
      await this.$store.dispatch("fetchTenantId");
      // this.tenant_id = this.$store.getters.getTenantId;
    }
    this.package_id = this.$route.params.packageName;
    await this.fetchEmailSequences();
    await this.getSelectedEmailSequences();
  },
  methods: {
    setValue(val, type) {
        let body = {
          package_id: this.package_id,
          default_email_sequence: [
            {
              email_sequence_id: val.id,
              external_id_type: type,
            },
          ],
        };
        console.log('val :>> ', val);
      this.selected_sequence[type] = val;
      this.setNewEmailSequence(body)
    },

    async fetchEmailSequences() {
      try {
        this.email_option.isLoading = true;
        const url = `/email-sequence/linearlist?external_id=${this.getTenantId}`;
        let { data } = await axios(url);
        if (data) {
          this.email_option.options = data || [];
        }
        this.email_option.isLoading = false;
      } catch (error) {
        console.log("error :>> fetchEmailSequences ", error);
      }
    },

    async getSelectedEmailSequences() {
      try {
        const { data } = await axios(
          `/screening-package/${this.package_id}/default-email-sequence`
        );
        if (data.data && data.data.length) {
          console.log("data.data :>> ", data.data);
          data.data.forEach(seq => {
              this.selected_sequence[seq.external_id_type] = this.email_option.options.find(ems => ems.id === seq.email_sequence_id) || null 
          });
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },

    async setNewEmailSequence(payload) {
      try {
        const { data } = await axios.post(
          `screening-package/update-default-email-sequence`,
          payload
        );

        // console.log('data :>> ', data);
        if (data) {
            this.$toast.success(data.message || 'Default Email Sequence Updated')
        }
      } catch (error) {
          console.log('error :>> ', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .dropdown {
    border-color: #dedede;
    @apply bg-white;
  }
}
</style>